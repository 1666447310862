import { styled } from 'Theme/stitches.config';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useEffect, useState } from 'react';
import MiniCartProduct from 'Commerce/Cart/MiniCart/MiniCartProduct';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import Heading from 'DesignSystem/Typography/Headings/Heading';

import OrderModel from 'Models/Checkout/OrderConfirmation/OrderModel.interface';
import { GetCart } from 'Commerce/Cart/Cart';
import MiniCartSummary from 'Commerce/Cart/MiniCart/MiniCartSummary';
import AccordionItem from 'DesignComponents/Molecules/Accordion/AccordionItem';

import GiftCardCode from 'Commerce/Molecules/GiftCardCard/GiftCardCode';
import PromotionCode from 'Commerce/Molecules/PromotionCode/PromotionCode';
import useAccordionMode from 'Shared/Hooks/useAccordionMode';

const ORDER_SUMMARY_PADDING = 32;

type PropType = {
  order?: OrderModel;
  readOnly?: boolean;
};

function OrderSummary({ readOnly = false, order }: PropType) {
  const {
    checkoutLabels: { yourOrder },
    giftCardLabels: { giftCardCheckoutFormHeading },
    productLabels: { discount },
  } = useTranslationData();

  const [openedIndex, setOpenedIndex, handleToggle] = useAccordionMode();

  const { languageRoute } = useAppSettingsData();

  let { cart } = GetCart(languageRoute);

  if (readOnly && order) cart = order.cart;

  const [cartItems, setCartItems] = useState<VariationModel[]>([]);

  useEffect(() => {
    if (cart && cart?.lineItems) {
      setCartItems(cart.lineItems);
    }
  }, [cart]);

  const [stickyHeaderFix, setStickyHeaderFix] = useState({ top: 0 });

  useEffect(() => {
    const header = document.getElementById('checkout-header');

    function adjustForHeaderPosition() {
      const bottomOfHeader = header?.getBoundingClientRect().bottom;

      if (bottomOfHeader !== undefined) {
        const top = Math.max(0, bottomOfHeader) + ORDER_SUMMARY_PADDING;
        setStickyHeaderFix({ top });
      }
    }

    document.addEventListener('scroll', adjustForHeaderPosition);

    return () => {
      document.removeEventListener('scroll', adjustForHeaderPosition);
    };
  });

  const orderSummaryItems = [
    {
      header: giftCardCheckoutFormHeading,
      component: <GiftCardCode view="checkout" />,
    },
    {
      header: discount,
      component: <PromotionCode />,
    },
  ];

  if (!cart) return null;

  return (
    <CartContainer css={stickyHeaderFix}>
      <Heading tag="h2" size="s">
        {yourOrder}
      </Heading>

      <>
        <CartContent>
          {cartItems.map((item, index) => {
            return (
              <MiniCartProduct
                item={item}
                key={index}
                inCheckout={true}
                readonly={readOnly}
              />
            );
          })}
        </CartContent>

        <MiniCartSummary cart={cart} readOnly={readOnly} />
        {!readOnly && (
          <GiftCardWrapper>
            {orderSummaryItems.map((item, index) => (
              <AccordionItem
                key={index}
                header={item.header}
                opened={index === openedIndex}
                onOpening={() => handleToggle(index)}
                onClosing={() => setOpenedIndex(null)}
              >
                {item.component}
              </AccordionItem>
            ))}
          </GiftCardWrapper>
        )}
      </>
    </CartContainer>
  );
}

const CartContainer = styled(
  'div',
  {
    display: 'flex',
    flexDirection: 'column',
    w: '100%',
    h: 'auto',
    my: 0,
    mx: 0,
    backgroundColor: '$checkoutPageBackgroundSecondary',
    '@bpMin721': {
      position: 'sticky',
      t: 24,
    },
    variants: {
      noPadding: {
        true: {
          px: 0,
        },
      },
    },
  },
  {
    '@bpMin721': {
      position: 'webkit-sticky',
      t: 24,
    },
  }
);

const CartContent = styled('div', {
  mt: 6,
});

const GiftCardWrapper = styled('div', {
  mt: 6,
  borderTop: '1px solid $interactiveBorderSeparator',
});

export default OrderSummary;
